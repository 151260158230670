import { useHistory } from "react-router";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Img from "../../assets/images/thanks.svg";
import CloseIcon from "../../assets/icons/xicon.svg";
import styles from "./style.module.scss";

type AlertType = {
  show: boolean;
  setShow: Function;
};

export const Alert = ({ show, setShow }: AlertType) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = () => {
    setShow(false);
    history.push("/");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Body className={styles.modal}>
          <Button
            onClick={() => handleClose()}
            className={styles.close}
            variant=""
          >
            <img src={CloseIcon} alt="close" className="w-100" />
          </Button>
          <img src={Img} alt="thanks" />
          <h3 className="bold-text my-3">{t("components.alert.thanks")}</h3>
          <p>{t("components.alert.soon")}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};
