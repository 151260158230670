import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  Col,
  Row,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import brownArrow from "../../assets/icons/brown-arrow.svg";
import infoIcon from "../../assets/icons/info.svg";
import uploadIcon from "../../assets/icons/upload-file.svg";
import ksaIcon from "../../assets/icons/ksa.svg";
import dateIcon from "../../assets/icons/date.svg";
import styles from "./style.module.scss";

type formValuesType = {
  name: string;
  mobile: string;
  email: string;
  city: string;
  gender: string;
  nationality: string;
  id_number: string;
  id_card_image: string;
  driving_license_image: string;
  date_of_birth: string;
};

type BasicInfoType = {
  handleFormSubmit: Function;
  data: formValuesType;
};

export const BasicInfo = ({ handleFormSubmit, data }: BasicInfoType) => {
  const [formData, setFormData] = useState({ ...data });
  const { t, i18n } = useTranslation();

  const schema = Yup.object().shape({
    name: Yup.string().required(t("form.errors.required")),
    mobile: Yup.string()
      .required(t("form.errors.required"))
      .min(9, t("form.errors.numbers.ten"))
      .max(9, t("form.errors.numbers.ten"))
      .matches(/^5[0-9]+$/, t("form.error.mobile.format")),
    email: Yup.string()
      .required(t("form.errors.required"))
      .email(t("form.errors.email")),
    city: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    gender: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    nationality: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    id_number: Yup.string()
      .required(t("form.errors.required"))
      .min(10, t("form.errors.numbers.ten"))
      .max(10, t("form.errors.numbers.ten")),
    id_card_image: Yup.string().required(t("form.errors.required")),
    driving_license_image: Yup.string().required(t("form.errors.required")),
    date_of_birth: Yup.string()
      .required(t("form.errors.required"))
      .min(10, t("form.error.date"))
      .max(10, t("form.error.date")),
  });

  const cityOptions = [
    { value: "riyadh", label: "Riyadh" },
    { value: "jeddah", label: "Jeddah" },
    { value: "dammam", label: "Dammam" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const nationalityOptions = [{ value: "saudi", label: "Saudi" }];

  const handleSelectChange = (
    values: {
      value: string;
      label: string;
    },
    name: string,
    props: any
  ) => {
    setFormData({
      ...props.values,
      [name]: values.label,
    });
  };

  const handleFileUploadClick = (name: string) => {
    const file = document.getElementById(name);
    file?.click();
  };

  const handleFileUploadChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: string,
    props: any
  ) => {
    setFormData({
      ...props.values,
      [name]: e.target.value,
    });
  };

  const handleDateValuesChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const today = new Date();
    if (type === "day") {
      if (e.target.value.length >= 2) {
        if (parseInt(e.target.value) > 31) {
          return "31";
        }
        return e.target.value.slice(0, 2);
      }
      if (parseInt(e.target.value) < 10 && parseInt(e.target.value) > 0) {
        return e.target.value;
      }
      if (parseInt(e.target.value) === 0 || isNaN(parseInt(e.target.value))) {
        return "";
      }
    }
    if (type === "month") {
      if (e.target.value.length >= 2) {
        if (parseInt(e.target.value) > 12) {
          return "12";
        }
        return e.target.value.slice(0, 2);
      }
      if (parseInt(e.target.value) < 10 && parseInt(e.target.value) > 0) {
        return e.target.value;
      }
      if (parseInt(e.target.value) === 0 || isNaN(parseInt(e.target.value))) {
        return "";
      }
    }
    if (type === "year") {
      if (e.target.value.length >= 4) {
        if (parseInt(e.target.value) >= today.getFullYear()) {
          return today.getFullYear().toString();
        }
        return e.target.value.slice(0, 4);
      }
      if (parseInt(e.target.value) === 0 || isNaN(parseInt(e.target.value))) {
        return "";
      }
    }
    return e.target.value;
  };

  const handleDateChange = (props: any, type: string, value: string) => {
    const dob = props.values.date_of_birth.split("-");

    const day =
      type === "day"
        ? parseInt(value) < 10 && parseInt(value) > 0
          ? `0${value}`
          : value
        : dob[0];

    const month =
      type === "month"
        ? parseInt(value) < 10 && parseInt(value) > 0
          ? `0${value}`
          : value
        : dob[1];

    const year = type === "year" ? value : dob[2];

    setFormData({
      ...props.values,
      date_of_birth: `${day}-${month}-${year}`,
    });
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => handleFormSubmit(values)}
      initialValues={formData}
      enableReinitialize
    >
      {(props) => (
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit();
          }}
        >
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                name="name"
                value={props.values.name}
                onChange={props.handleChange}
                className={`w-100 border-0 ${styles.input}`}
              />
              <Form.Label
                className="w-100"
                style={{
                  right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                  left: `${i18n.language === "en" ? "0" : "40%"}`,
                }}
              >
                {t("form.name")}
              </Form.Label>
              <div className="px-2 pt-2 text-danger">
                <small>{props.touched.name ? props.errors.name : ""}</small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="mb-3">
              <div
                className={`w-100 border-0 ${styles.input} d-flex align-items-center justify-content-between px-3`}
                dir="ltr"
              >
                <div className="d-flex align-items-center">
                  <img src={ksaIcon} alt="ksa" />{" "}
                  <span className="mx-2">+966</span>
                  <Form.Control
                    type="tel"
                    name="mobile"
                    value={props.values.mobile}
                    onChange={props.handleChange}
                    className={`w-100 border-0 ${styles.input}`}
                    placeholder="00 - 000 - 0000"
                  />
                </div>
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>{props.touched.mobile ? props.errors.mobile : ""}</small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="email"
                name="email"
                value={props.values.email}
                onChange={props.handleChange}
                className={`w-100 border-0 ${styles.input}`}
              />
              <Form.Label
                className="w-100"
                style={{
                  right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                  left: `${i18n.language === "en" ? "0" : "40%"}`,
                }}
              >
                {t("form.email")}
              </Form.Label>
              <div className="px-2 pt-2 text-danger">
                <small>{props.touched.email ? props.errors.email : ""}</small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
              >
                <Form.Label
                  style={{
                    right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "unset"}`,
                  }}
                  className={`mb-0 mx-2 ${styles.label}`}
                >
                  {t("form.city")}
                </Form.Label>
                <Select
                  value={{
                    value: props.values.city.toString(),
                    label: props.values.city.toString(),
                  }}
                  options={cityOptions}
                  className={`w-100 border-0 mt-4`}
                  name="city"
                  onChange={(values) =>
                    handleSelectChange(
                      values || { value: "0", label: "0" },
                      "city",
                      props
                    )
                  }
                />
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>{props.touched.city ? props.errors.city : ""}</small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
              >
                <Form.Label
                  style={{
                    right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "unset"}`,
                  }}
                  className={`mb-0 mx-2 ${styles.label}`}
                >
                  {t("form.gendar")}
                </Form.Label>
                <Select
                  value={{
                    value: props.values.gender.toString(),
                    label: props.values.gender.toString(),
                  }}
                  options={genderOptions}
                  className={`w-100 border-0 mt-4`}
                  name="gender"
                  onChange={(values) =>
                    handleSelectChange(
                      values || { value: "0", label: "0" },
                      "gender",
                      props
                    )
                  }
                />
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>{props.touched.gender ? props.errors.gender : ""}</small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Row>
              <Col lg={6}>
                <Form.Group className="form-floating mb-3">
                  <div
                    className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
                  >
                    <Form.Label
                      style={{
                        right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                        left: `${i18n.language === "en" ? "0" : "unset"}`,
                      }}
                      className={`mb-0 mx-2 ${styles.label}`}
                    >
                      {t("form.nationality")}
                    </Form.Label>
                    <Select
                      value={{
                        value: props.values.nationality.toString(),
                        label: props.values.nationality.toString(),
                      }}
                      options={nationalityOptions}
                      className={`w-100 border-0 mt-4`}
                      name="nationality"
                      onChange={(values) =>
                        handleSelectChange(
                          values || { value: "0", label: "0" },
                          "nationality",
                          props
                        )
                      }
                    />
                  </div>
                  <div className="px-2 pt-2 text-danger">
                    <small>
                      {props.touched.nationality
                        ? props.errors.nationality
                        : ""}
                    </small>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="form-floating mb-3">
                  <Form.Control
                    type="number"
                    name="id_number"
                    value={props.values.id_number}
                    onChange={props.handleChange}
                    className={`w-100 border-0 ${styles.input} ${styles.selectHeight}`}
                  />
                  <Form.Label
                    className="w-100"
                    style={{
                      right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                      left: `${i18n.language === "en" ? "0" : "40%"}`,
                    }}
                  >
                    {t("form.id")}
                  </Form.Label>
                  <div className="px-2 pt-2 text-danger">
                    <small>
                      {props.touched.id_number ? props.errors.id_number : ""}
                    </small>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`w-100 border-0 ${styles.input} d-flex align-items-center justify-content-between px-2`}
                onClick={() => handleFileUploadClick("id_card_image")}
              >
                {props.values.id_card_image !== "" ? (
                  <small className="m-0 text-muted d-flex align-items-center">
                    <img src={uploadIcon} alt="upload" className="mx-2" />
                    {props.values.id_card_image}
                  </small>
                ) : (
                  <>
                    <small className="m-0 text-muted d-flex align-items-center">
                      <img src={uploadIcon} alt="upload" className="mx-2" />
                      {t("form.id.image")}
                    </small>
                    <small
                      className={`${
                        i18n.language === "ar" ? "ml-5" : "mr-5"
                      } text-muted`}
                    >
                      {t("form.id.image.types")}
                    </small>
                  </>
                )}
              </div>
              <div
                className={styles.tooltip}
                style={{
                  left: `${i18n.language === "ar" ? "0" : "unset"}`,
                  right: `${i18n.language === "en" ? "0" : "unset"}`,
                }}
              >
                <OverlayTrigger
                  trigger={["hover", "focus", "click"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover
                      id={`popover-positioned-top`}
                      className="border-0 rounded"
                    >
                      <Popover.Content className="text-center">
                        <p className="text-brown">upload your ID image</p>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button variant="">
                    <img src={infoIcon} alt="info" />
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.id_card_image
                    ? props.errors.id_card_image
                    : ""}
                </small>
              </div>
            </Form.Group>
            <input
              type="file"
              id="id_card_image"
              name="id_card_image"
              accept="image/*,application/pdf"
              hidden
              onChange={(e) =>
                handleFileUploadChange(e, "id_card_image", props)
              }
            />
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`w-100 border-0 ${styles.input} d-flex align-items-center justify-content-between px-2`}
                onClick={() => handleFileUploadClick("driving_license_image")}
              >
                {props.values.driving_license_image !== "" ? (
                  <small className="m-0 text-muted d-flex align-items-center">
                    <img src={uploadIcon} alt="upload" className="mx-2" />
                    {props.values.driving_license_image}
                  </small>
                ) : (
                  <>
                    <small className="m-0 text-muted d-flex align-items-center">
                      <img src={uploadIcon} alt="upload" className="mx-2" />
                      {t("form.license.image")}
                    </small>
                    <small
                      className={`${
                        i18n.language === "ar" ? "ml-5" : "mr-5"
                      } text-muted`}
                    >
                      {t("form.id.image.types")}
                    </small>
                  </>
                )}
              </div>
              <div
                className={styles.tooltip}
                style={{
                  left: `${i18n.language === "ar" ? "0" : "unset"}`,
                  right: `${i18n.language === "en" ? "0" : "unset"}`,
                }}
              >
                <OverlayTrigger
                  trigger={["hover", "focus", "click"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover
                      id={`popover-positioned-top`}
                      className="border-0 rounded"
                    >
                      <Popover.Content className="text-center">
                        <p className="text-brown">
                          upload your driving license image
                        </p>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button variant="">
                    <img src={infoIcon} alt="info" />
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.driving_license_image
                    ? props.errors.driving_license_image
                    : ""}
                </small>
              </div>
            </Form.Group>
            <input
              type="file"
              id="driving_license_image"
              name="driving_license_image"
              accept="image/*,application/pdf"
              hidden
              onChange={(e) =>
                handleFileUploadChange(e, "driving_license_image", props)
              }
            />
          </Form.Row>
          <Form.Row>
            <Row className="align-items-center">
              <Col
                sm={4}
                lg={4}
                className="d-flex align-items-center mb-3 mb-lg-0"
              >
                <img src={dateIcon} alt="date" height={24} />{" "}
                <span className="mx-2">{t("form.date_of_birth")}</span>
              </Col>
              <Col sm={8} lg={8}>
                <Form.Group>
                  <div
                    className={`w-100 border-0 ${styles.input} d-flex align-items-center justify-content-between`}
                    dir="ltr"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <Form.Control
                        type="text"
                        name="mobile"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = handleDateValuesChange(e, "day");
                          handleDateChange(props, "day", e.target.value);
                        }}
                        className={`w-100 text-center border-0 ${styles.input}`}
                        placeholder={t("form.date_of_birth.day")}
                      />
                      <Form.Control
                        type="text"
                        name="mobile"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = handleDateValuesChange(e, "month");
                          handleDateChange(props, "month", e.target.value);
                        }}
                        style={{ borderTop: 0, borderBottom: 0 }}
                        className={`w-100 text-center rounded-0 ${styles.input}`}
                        placeholder={t("form.date_of_birth.month")}
                      />
                      <Form.Control
                        type="text"
                        name="mobile"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          e.target.value = handleDateValuesChange(e, "year");
                          handleDateChange(props, "year", e.target.value);
                        }}
                        className={`w-100 text-center border-0 ${styles.input}`}
                        placeholder={t("form.date_of_birth.year")}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
              <Col lg={12}>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.date_of_birth
                      ? props.errors.date_of_birth
                      : ""}
                  </small>
                </div>
              </Col>
            </Row>
          </Form.Row>
          <div className="d-flex align-items-center justify-content-end mt-3">
            <Button
              className={`${styles.submit} bold-text border-0 d-flex align-items-center justify-content-around`}
              type="submit"
              variant="primary"
            >
              <span>{t("form.next")}</span>
              <img
                src={brownArrow}
                alt="next"
                className={`${i18n.language === "ar" ? "rotate" : ""}`}
              />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
