import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";

type CardTypes = {
  icon: string;
  title: string;
  body: string;
};

export const FeatureCard = ({ title, body, icon }: CardTypes) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`my-3 ${i18n.language === "ar" ? "text-end" : "text-start"} ${
        styles.card
      }`}
    >
      <div className="mb-3">
        <img src={icon} alt={title} />
      </div>
      <p className="bold-text mb-3">{title}</p>
      <p>{body}</p>
    </div>
  );
};
