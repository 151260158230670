import { useState } from "react";
import { useHistory } from "react-router-dom";
import { BasicInfo } from "./basicInfo";
import { CarInfo } from "./carInfo";

type PartnerRegistrationType = {
  setTabStep: Function;
};

export const RiderRegistration = ({ setTabStep }: PartnerRegistrationType) => {
  const [step, setStep] = useState(1);
  const [stepOneData, setStepOneData] = useState({
    name: "",
    mobile: "",
    email: "",
    city: "",
    gender: "",
    nationality: "",
    id_number: "",
    id_card_image: "",
    driving_license_image: "",
    date_of_birth: "--",
  });
  const [stepTwoData, setStepTwoData] = useState({
    car_number: "",
    manufacturer: "",
    car_year: "",
    car_type: "",
  });
  const history = useHistory();

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (data: any, event?: { preventDefault: () => void }) => {
    event?.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "rider-apply",
        ...data,
      }),
    })
      .then(() => {
        history.push("/");
      })
      .catch((error) => console.log(error));
  };

  const handleFormSubmit = (values: any) => {
    if (step === 1) {
      setStep(step + 1);
      setTabStep(step + 1);
      setStepOneData(values);
    }
    if (step === 2) {
      setStepTwoData(values);
      handleSubmit({
        ...stepOneData,
        ...values,
      });
    }
  };

  const handleBackClick = (curentStep: number) => {
    setStep(curentStep - 1);
    setTabStep(curentStep - 1);
  };

  if (step === 2) {
    return (
      <CarInfo
        handleFormSubmit={handleFormSubmit}
        data={stepTwoData}
        handleBackClick={handleBackClick}
      />
    );
  }

  return <BasicInfo handleFormSubmit={handleFormSubmit} data={stepOneData} />;
};
