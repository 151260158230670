import { useState } from "react";
import { Container, Row, Col, Nav, Tab, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import arrow from "../../assets/icons/arrow.svg";
import styles from "./style.module.scss";

export const RiseSection = () => {
  const [activeTab, setActiveTab] = useState("pages.landing.company");
  const { t, i18n } = useTranslation();

  return (
    <Container className={styles.riseContainer}>
      <div className="anchor" id="why_mazaj"></div>
      <Container>
        <Row>
          <Col lg={6} sm={12}>
            <p
              className={`${styles.title} bold-text mb-0`}
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t("pages.landing.niceCoffee")}
            </p>
            <h4
              className={`text-orange mt-5 ${styles.oneApp} ${
                i18n.language === "ar" ? "bold-amman" : "bold-caveat"
              }`}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {t("pages.landing.oneApp")}
            </h4>
          </Col>
          <Col lg={6} sm={12}>
            <p
              className="text-brown mb-0 mb-lg-5"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t("pages.landing.description")}
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row>
          <Col lg={12}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row data-aos="fade-up" data-aos-duration="1200">
                <Col lg={6} sm={12}>
                  <Nav
                    variant="pills"
                    className="flex-column flex-nowrap d-lg-flex d-none"
                  >
                    <Nav.Item>
                      <Nav.Link
                        className="d-flex align-items-center justify-content-between bg-transparent pb-4 px-0 border-bottom tabs-width text-brown bold-text"
                        eventKey="pages.landing.company"
                        onSelect={() => setActiveTab("pages.landing.company")}
                      >
                        {t("pages.landing.company")}{" "}
                        {activeTab === "pages.landing.company" && (
                          <img
                            className={i18n.language === "ar" ? "rotate" : ""}
                            src={arrow}
                            alt="arrow"
                          />
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="d-flex align-items-center justify-content-between bg-transparent mt-4 pb-4 px-0 border-bottom tabs-width text-brown bold-text"
                        eventKey="mission"
                        onSelect={() => setActiveTab("mission")}
                      >
                        {t("pages.landing.mission")}{" "}
                        {activeTab === "mission" && (
                          <img
                            className={i18n.language === "ar" ? "rotate" : ""}
                            src={arrow}
                            alt="arrow"
                          />
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="d-flex align-items-center justify-content-between bg-transparent mt-4 pb-4 px-0 border-bottom tabs-width text-brown bold-text"
                        eventKey="values"
                        onSelect={() => setActiveTab("values")}
                      >
                        {t("pages.landing.values")}{" "}
                        {activeTab === "values" && (
                          <img
                            className={i18n.language === "ar" ? "rotate" : ""}
                            src={arrow}
                            alt="arrow"
                          />
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Form.Control
                    as="select"
                    className={`d-lg-none d-block bold-text text-brown w-100 p-2 rounded mb-5 ${styles.selectArrow}`}
                    style={{
                      backgroundPosition: `${
                        i18n.language === "ar" ? "5% center" : "95% center"
                      }`,
                    }}
                    onChange={(e) => setActiveTab(e.target.value)}
                    custom
                  >
                    <option value="pages.landing.company">
                      {t("pages.landing.company")}
                    </option>
                    <option value="mission">
                      {t("pages.landing.mission")}
                    </option>
                    <option value="values">{t("pages.landing.values")}</option>
                  </Form.Control>
                </Col>
                <Col lg={6} sm={12} d-flex align-items-center>
                  <Tab.Content>
                    <Tab.Pane
                      eventKey="pages.landing.company"
                      active={activeTab === "pages.landing.company"}
                    >
                      <p className="text-brown bold-text mb-5">
                        {t("pages.landing.mazajPartners")}
                      </p>
                    </Tab.Pane>
                    <Tab.Pane
                      eventKey="mission"
                      active={activeTab === "mission"}
                    >
                      <p className="text-brown bold-text align-self-center">
                        {t("pages.landing.mission.description")}
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="values" active={activeTab === "values"}>
                      <p className="text-brown bold-text align-self-center">
                        {t("pages.landing.importantValues")}
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
