import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PartnerRegistration } from "../../containers/partnerRegistration";
import language from "../../assets/icons/language.svg";
import back from "../../assets/icons/back.svg";
import styles from "./style.module.scss";

export const PartnerRegistrationPage = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImg}>
        <div className={`mx-5 bold-text w-50 text-white ${styles.imgText}`}>
          <p>{t("pages.partner.placeholder")}</p>
        </div>
      </div>
      <Container>
        <Row>
          <Col lg={7} md={7}></Col>
          <Col lg={5} md={5} className="py-5">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <Link to="/">
                <img
                  src={back}
                  alt="Mazaj"
                  className={`${i18n.language === "ar" ? "rotate" : ""}`}
                />
              </Link>
              <Button
                onClick={handleLanguageChange}
                variant="primary"
                className={`${styles.langBtn} bold-text d-flex align-items-center justify-content-between`}
              >
                <img src={language} alt={t("components.header.language")} />{" "}
                {t("components.header.language")}
              </Button>
            </div>
            <p className={`bold-text text-brown ${styles.title}`}>
              {t("form.partners.title")}
            </p>
            <PartnerRegistration />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
