import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import styles from "./style.module.scss";
import googlePlay from "../../assets/icons/googlePlay.svg";
import appStore from "../../assets/icons/appStore.svg";
import mainImg from "../../assets/images/main.svg";
import street from "../../assets/images/street.svg";

export const MainSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={styles.bgImage}
        style={{
          backgroundImage: `url(${mainImg}), url(${street})`,
        }}
      >
        <h1 className={styles.headerText}>
          {" "}
          {t("page.landing.mainHeaderText")}
        </h1>
        <div className="anchor" id="main"></div>
        <div className={`w-100 ${styles.homeContainer}`}>
          <p
            className="mb-4 bold-text w-100 text-center"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            {t("pages.landing.enjoy")}
          </p>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="primary"
              className={`mx-1 ${styles.appBtn}`}
              data-aos="zoom-in-up"
              onClick={(e) =>
                window.open(
                  "https://apps.apple.com/app/mazaj-%D9%85%D8%B2%D8%A7%D8%AC/id1579221186",
                  "_blank",
                  "noopener"
                )
              }
              data-aos-duration="1000"
            >
              <img src={appStore} alt="app store" />
            </Button>
            <Button
              variant="primary"
              className={`mx-1 ${styles.appBtn}`}
              data-aos="zoom-in-up"
              data-aos-duration="1000"
              onClick={(e) =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.mazaj.customer",
                  "_blank",
                  "noopener"
                )
              }
            >
              <img src={googlePlay} alt="google play" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
