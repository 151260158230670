import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import styles from "./style.module.scss";
import getImages from "../../assets/helper/partnerImages";
import { useState, useEffect } from "react";

interface Image {
  src: string;
  alt: string;
}

export const PartnersSection = () => {
  const { t } = useTranslation();
  const [images, setImages] = useState<Array<Image>>([]);

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = await getImages();
      setImages(fetchedImages);
    };

    fetchImages();
  }, []);

  return (
    <div className={`text-center ${styles.main}`}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={12}>
            <h1
              className="bold-text text-brown"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              {t("pages.landing.partners.title")}
            </h1>
            <h5
              className="bold-text text-brown mb-5"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              {t("pages.landing.partners.description")}
            </h5>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          {images?.map((partner: Image) => (
            <Col
              key={partner.src}
              className={styles.card}
              lg={2}
              md={6}
              sm={6}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <img src={partner.src} alt={partner.alt} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
