import { Header } from "../../containers/header";
import { RiseSection } from "../../containers/riseSection";
import { MainSection } from "../../containers/mainSection";
import { FeaturesSection } from "../../containers/featuresSection";
import { ProductsSection } from "../../containers/productsSection";
import { BeWithUs } from "../../containers/beWithUs";
import { PartnersSection } from "../../containers/partnersSection";
import { FooterSection } from "../../containers/footerSection";

export const LandingPage = () => {
  return (
    <>
      <Header />
      <MainSection />
      <RiseSection />
      <FeaturesSection />
      <ProductsSection />
      <BeWithUs />
      <PartnersSection />
      <FooterSection />
    </>
  );
};
