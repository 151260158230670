const getImages = async () => {
  const images = [];
  const imagePaths = await importAllImages();
  for (const imagePath of imagePaths) {
    const image = {
      src: imagePath,
      alt: "partnerImage",
    };
    images.push(image);
  }
  return images;
};

const importAllImages = async () => {
  const images = [];
  const context = require.context("../../images/partners", false);
  const keys = context.keys().sort((a, b) => {
    const aNumber = a.split("/").pop()?.split(".")[0];
    const bNumber = b.split("/").pop()?.split(".")[0];

    return Number(aNumber) - Number(bNumber);
  });

  for (const key of keys) {
    const image = await import(`../../images/partners/${key.slice(2)}`);
    images.push(image.default);
  }
  return images;
};

export default getImages;
