import { useTranslation } from "react-i18next";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import styles from "./style.module.scss";
import logo from "../../assets/icons/final-logo.svg";
import logoEn from "../../assets/icons/final-logo.svg";
import partner from "../../assets/icons/partner.svg";
import menu from "../../assets/icons/menu.svg";
import close from "../../assets/icons/close.svg";
import language from "../../assets/icons/language.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <Navbar
      className={`${styles.navContainer} fixed-top bold-text`}
      expand="lg"
      expanded={menuOpen}
    >
      <Container className="flex-row-reverse flex-lg-row">
        <Button
          onClick={handleLanguageChange}
          variant="primary"
          className={`${styles.langBtn} d-block d-lg-none`}
        >
          <img
            src={language}
            alt={t("components.header.language")}
            className="mx-1"
          />{" "}
          {t("components.header.language")}
        </Button>
        <Navbar.Brand href="#">
          <img src={i18n.language === "ar" ? logo : logoEn} alt="Mazaj" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={styles.menu}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <img src={menuOpen ? close : menu} alt="menu" />
        </Navbar.Toggle>
        <Navbar.Collapse className={styles.collapse} id="basic-navbar-nav">
          <Nav className={i18n.language === "ar" ? "me-auto" : "ms-auto"}>
            <Nav.Link
              className={`py-0 align-items-center d-block d-lg-flex ${styles.hover}`}
              href="#why_mazaj"
              onClick={() => setMenuOpen(false)}
            >
              {t("pages.landing.whyMazaj")}
            </Nav.Link>
            <Nav.Link
              className={`py-0 align-items-center d-block d-lg-flex ${styles.hover}`}
              href="#features"
              onClick={() => setMenuOpen(false)}
            >
              {t("pages.landing.features")}
            </Nav.Link>
            <Nav.Link
              className={`py-0 align-items-center d-block d-lg-flex ${styles.hover}`}
              href="#categories"
              onClick={() => setMenuOpen(false)}
            >
              {t("pages.landing.categories")}
            </Nav.Link>
            <Nav.Link
              className={`py-0 align-items-center d-block d-lg-flex ${styles.hover}`}
              href="#partners"
              onClick={() => setMenuOpen(false)}
            >
              {t("pages.landing.partners")}
            </Nav.Link>
            <Link className="py-0 mx-2" to="/partner-registration">
              <Button variant="" className={styles.partnerBtn}>
                <img src={partner} alt="Be partner" />{" "}
                {t("pages.landing.bePartner")}
              </Button>
            </Link>
            <Nav.Link className="p-0" href="#">
              <Button
                onClick={handleLanguageChange}
                variant="primary"
                className={`${styles.langBtn} d-none d-lg-block`}
              >
                <img src={language} alt={t("components.header.language")} />{" "}
                {t("components.header.language")}
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
