import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RiderRegistration } from "../../containers/riderRegistration";
import language from "../../assets/icons/language.svg";
import logo from "../../assets/icons/final-logo.svg";
import logoEn from "../../assets/icons/final-logo.svg";
import styles from "./style.module.scss";
import { useState } from "react";

export const RiderRegistrationPage = () => {
  const [tabStep, setTabStep] = useState(1);
  const { t, i18n } = useTranslation();

  const handleLanguageChange = () => {
    if (i18n.language === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("language", "ar");
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bgImg}>
        <div className={`mx-5 bold-text w-50 text-white ${styles.imgText}`}>
          <p>{t("pages.rider.placeholder")}</p>
        </div>
      </div>
      <Container>
        <Row>
          <Col lg={7}></Col>
          <Col lg={5} className="py-5">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <Link to="/">
                <img src={i18n.language === "ar" ? logo : logoEn} alt="Mazaj" />
              </Link>
              <Button
                onClick={handleLanguageChange}
                variant="primary"
                className={`${styles.langBtn} bold-text d-flex align-items-center justify-content-between`}
              >
                <img src={language} alt={t("components.header.language")} />{" "}
                {t("components.header.language")}
              </Button>
            </div>
            <p className={`bold-text text-brown ${styles.title}`}>
              {t("form.riders.title")}
            </p>
            <div className="d-flex align-items-center mb-5">
              <p
                className={`bold-text text-brown mb-0 ${styles.sections} ${
                  tabStep >= 1 && styles.active
                }`}
                style={{
                  marginLeft: `${i18n.language === "ar" ? "40px" : "0"}`,
                  marginRight: `${i18n.language === "en" ? "40px" : "0"}`,
                }}
              >
                {t("form.riders.step.one")}
              </p>
              <p
                className={`bold-text text-brown mb-0 ${styles.sections} ${
                  tabStep >= 2 && styles.active
                }`}
                style={{
                  marginLeft: `${i18n.language === "ar" ? "40px" : "0"}`,
                  marginRight: `${i18n.language === "en" ? "40px" : "0"}`,
                }}
              >
                {t("form.riders.step.two")}
              </p>
            </div>
            <RiderRegistration setTabStep={setTabStep} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
