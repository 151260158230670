import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import variety from "../../assets/icons/variety.svg";
import support from "../../assets/icons/support.svg";
import scheduling from "../../assets/icons/scheduling.svg";
import price from "../../assets/icons/price.svg";
import delivery from "../../assets/icons/delivery.svg";
import convenient from "../../assets/icons/convenient.svg";
import { FeatureCard } from "../../components/featureCard";
import styles from "./style.module.scss";

export const FeaturesSection = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    initialSlide: 1,
    rtl: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {},
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={`text-center ${styles.bgImage}`}>
        <div className="anchor" id="features"></div>

        <h1
          className="bold-text text-brown mb-lg-5 mt-lg-0 mt-5 mb-0"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {t("pages.landing.mazajFeatures")}
        </h1>
        <Slider className="d-lg-none d-block" {...settings}>
          <div>
            <FeatureCard
              icon={variety}
              title={t("pages.landing.variety.title")}
              body={t("pages.landing.variety.description")}
            />
          </div>
          <div>
            <FeatureCard
              icon={delivery}
              title={t("pages.landing.delivery.title")}
              body={t("pages.landing.delivery.description")}
            />
          </div>
          <div>
            <FeatureCard
              icon={scheduling}
              title={t("pages.landing.subscriptions")}
              body={t("pages.landing.subscriptions.description")}
            />
          </div>
          <div>
            <FeatureCard
              icon={convenient}
              title={t("pages.landing.convenient.title")}
              body={t("pages.landing.convenient.description")}
            />
          </div>
          <div>
            <FeatureCard
              icon={support}
              title={t("pages.landing.support.title")}
              body={t("pages.landing.support.description")}
            />
          </div>
          <div>
            <FeatureCard
              icon={scheduling}
              title={t("pages.landing.payment.title")}
              body={t("pages.landing.payment.description")}
            />
          </div>
        </Slider>
        <Container className="d-lg-block d-none">
          <Row>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={variety}
                title={t("pages.landing.variety.title")}
                body={t("pages.landing.variety.description")}
              />
            </Col>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={delivery}
                title={t("pages.landing.delivery.title")}
                body={t("pages.landing.delivery.description")}
              />
            </Col>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={scheduling}
                title={t("pages.landing.subscriptions")}
                body={t("pages.landing.subscriptions.description")}
              />
            </Col>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={convenient}
                title={t("pages.landing.convenient.title")}
                body={t("pages.landing.convenient.description")}
              />
            </Col>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={support}
                title={t("pages.landing.support.title")}
                body={t("pages.landing.support.description")}
              />
            </Col>
            <Col lg={4} sm={12} data-aos="flip-up" data-aos-duration="1000">
              <FeatureCard
                icon={price}
                title={t("pages.landing.payment.title")}
                body={t("pages.landing.payment.description")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
