import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import coffees from "../../assets/images/coffees.png";
import pastries from "../../assets/images/pastries.png";
import chocolate from "../../assets/images/chocolate.png";
import dates from "../../assets/images/dates.png";
import sweets from "../../assets/images/sweets.png";
import juice from "../../assets/images/juice.png";
import coffeesBg from "../../assets/images/coffees-bg.svg";
import pastriesBg from "../../assets/images/pastries-bg.svg";
import chocolateBg from "../../assets/images/chocolate-bg.svg";
import datesBg from "../../assets/images/dates-bg.svg";
import sweetsBg from "../../assets/images/sweets-bg.svg";
import juiceBg from "../../assets/images/juice-bg.svg";
import { ProductCard } from "../../components/productCard";
import styles from "./style.module.scss";

export const ProductsSection = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`text-center ${styles.main}`}>
        <div className="anchor" id="categories"></div>
        <h1
          className="bold-text text-brown"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {t("pages.landing.categories.title")}
        </h1>
        <h5
          className="bold-text text-brown mb-5"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          {t("pages.landing.categories.description")}
        </h5>
        <Container>
          <Row className={styles.scrollable}>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={coffees}
                title={t("pages.landing.categories.coffee")}
                color={coffeesBg}
              />
            </Col>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={pastries}
                title={t("pages.landing.categories.pastries")}
                color={pastriesBg}
              />
            </Col>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={chocolate}
                title={t("pages.landing.categories.chocolate")}
                color={chocolateBg}
              />
            </Col>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={dates}
                title={t("pages.landing.categories.dates")}
                color={datesBg}
              />
            </Col>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={sweets}
                title={t("pages.landing.categories.sweets")}
                color={sweetsBg}
              />
            </Col>
            <Col
              className={styles.scrollableCol}
              lg={2}
              data-aos="flip-up"
              data-aos-duration="1000"
            >
              <ProductCard
                image={juice}
                title={t("pages.landing.categories.juice")}
                color={juiceBg}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
