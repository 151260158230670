import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LandingPage } from "./pages/landingPage";
import { PartnerRegistrationPage } from "./pages/partnerRegistrationPage";
import { RiderRegistrationPage } from "./pages/riderRegistrationPage";
import "./App.scss";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    AOS.init();
    i18n.changeLanguage(localStorage.getItem("language") || "ar");
  }, [i18n]);

  return (
    <div
      dir={i18n.language === "ar" ? "rtl" : "ltr"}
      className={i18n.language === "en" ? "en-wrapper" : ""}
    >
      <Router>
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/partner-registration">
            <PartnerRegistrationPage />
          </Route>
          <Route exact path="/rider-registration">
            <RiderRegistrationPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
