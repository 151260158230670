import { useTranslation } from "react-i18next";
import { Col, Container, Row, Button } from "react-bootstrap";
import footerImg from "../../assets/images/footerImg.png";
import logo from "../../assets/icons/final-logo-white.svg";
import logoEn from "../../assets/icons/final-logo-white.svg";
import twitter from "../../assets/icons/twitter.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/facebook.svg";
import googlePlay from "../../assets/icons/googlePlayWhite.svg";
import appStore from "../../assets/icons/appStoreWhite.svg";
import styles from "./style.module.scss";

export const FooterSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className={`text-center pb-5 ${styles.topFooter}`}>
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col lg={12} sm={6}>
              <img className={styles.topImg} src={footerImg} alt="footer" />
              <p className="bold-text text-white">{t("pages.landing.enjoy")}</p>
              <Row className="mt-5 align-items-center justify-content-center">
                <Col lg={12}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={(e) =>
                      window.open(
                        "https://apps.apple.com/app/mazaj-%D9%85%D8%B2%D8%A7%D8%AC/id1579221186",
                        "_blank",
                        "noopener"
                      )
                    }
                  >
                    <Button variant="dark" className={`mx-1 ${styles.appBtn}`}>
                      <img src={appStore} alt="app store" />
                    </Button>
                    <Button
                      variant="dark"
                      className={`mx-1 ${styles.appBtn}`}
                      onClick={(e) =>
                        window.open(
                          "https://play.google.com/store/apps/details?id=com.mazaj.customer",
                          "_blank",
                          "noopener"
                        )
                      }
                    >
                      <img src={googlePlay} alt="google play" />
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={`text-center pb-5 ${styles.bottomFooter}`}>
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col lg={2} sm={6}>
              <img
                className=""
                src={i18n.language === "ar" ? logo : logoEn}
                alt="mazaj"
              />
            </Col>
            <Col lg={6} sm={6} className="text-center mt-5 mt-lg-0">
              <Row className="align-items-center justify-content-center">
                <Col lg={3} sm={6} className="mb-3">
                  <a href="#why_mazaj" className="text-white bold-text">
                    {t("pages.landing.whyMazaj")}
                  </a>
                </Col>
                <Col lg={3} sm={6} className="mb-3">
                  <a href="#features" className="text-white bold-text">
                    {t("pages.landing.features")}
                  </a>
                </Col>
                <Col lg={3} sm={6} className="mb-3">
                  <a href="#categories" className="text-white bold-text">
                    {t("pages.landing.categories")}
                  </a>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center">
                <Col lg={12} sm={6}>
                  <span className="text-white">
                    {t("components.footer.copyRight", {
                      year: new Date().getFullYear(),
                    })}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={4} sm={12} className="text-center mt-3 mt-lg-0">
              <img
                className="mx-3"
                src={twitter}
                alt="twitter"
                onClick={(e) =>
                  window.open(
                    "https://twitter.com/MazajSaudi",
                    "_blank",
                    "noopener"
                  )
                }
              />
              <img
                className="mx-3"
                src={facebook}
                alt="facebook"
                onClick={(e) =>
                  window.open(
                    "https://www.facebook.com/MazajSaudi",
                    "_blank",
                    "noopener"
                  )
                }
              />
              <img
                className="mx-3"
                src={linkedin}
                alt="linkedin"
                onClick={(e) =>
                  window.open(
                    "https://www.linkedin.com/company/mazajsaudi/",
                    "_blank",
                    "noopener"
                  )
                }
              />
              <img
                className="mx-3"
                src={instagram}
                alt="instagram"
                onClick={(e) =>
                  window.open(
                    "https://www.instagram.com/mazajsaudi/",
                    "_blank",
                    "noopener"
                  )
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
