import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Row,
  Col,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import brownArrow from "../../assets/icons/brown-arrow.svg";
import carPlate from "../../assets/images/car-plate.png";
import infoIcon from "../../assets/icons/info.svg";
import styles from "./style.module.scss";

type formValuesType = {
  car_number: string;
  manufacturer: string;
  car_year: string;
  car_type: string;
};

type CarInfoType = {
  handleFormSubmit: Function;
  handleBackClick: Function;
  data: formValuesType;
};

export const CarInfo = ({
  handleFormSubmit,
  data,
  handleBackClick,
}: CarInfoType) => {
  const [formData, setFormData] = useState({ ...data });
  const { t, i18n } = useTranslation();
  let plate: string[] = [];

  const schema = Yup.object().shape({
    car_number: Yup.string().required(t("form.errors.required")),
    manufacturer: Yup.string().required(t("form.errors.required")),
    car_year: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    car_type: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
  });

  const yearsOptions = [
    { value: "2000", label: "2000" },
    { value: "2001", label: "2001" },
    { value: "2002", label: "2002" },
  ];

  const typeOptions = [
    { value: "jeep", label: "Jeep" },
    { value: "kube", label: "Kube" },
  ];

  const handleSelectChange = (
    values: {
      value: string;
      label: string;
    },
    name: string,
    props: any
  ) => {
    setFormData({
      ...props.values,
      [name]: values.label,
    });
  };

  const handlePlateChange = (letter: string, index: number, props: any) => {
    plate[index] = letter;

    if (plate.filter((x) => x).length === 7) {
      setFormData({
        ...props.values,
        car_number: plate.toString().replaceAll(",", ""),
      });
    }
  };

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => handleFormSubmit(values)}
      initialValues={formData}
      enableReinitialize
    >
      {(props) => (
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            props.handleSubmit();
          }}
        >
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <Row>
                <Col lg={12}>
                  <span className="text-muted">{t("form.car.number")}</span>
                </Col>
                <Col lg={12}>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    dir="ltr"
                  >
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 0, props);
                      }}
                      placeholder="1"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 1, props);
                      }}
                      placeholder="2"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 2, props);
                      }}
                      placeholder="3"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="number"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 3, props);
                      }}
                      placeholder="4"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 4, props);
                      }}
                      placeholder="A"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 5, props);
                      }}
                      placeholder="B"
                      className={`${styles.carInput}`}
                    />
                    <Form.Control
                      type="text"
                      onChange={(e) => {
                        e.target.value =
                          e.target.value.length > 1
                            ? e.target.value.charAt(0)
                            : e.target.value;
                        handlePlateChange(e.target.value, 6, props);
                      }}
                      placeholder="C"
                      className={`${styles.carInput}`}
                    />
                  </div>
                </Col>
              </Row>
              <div
                className={styles.tooltip}
                style={{
                  left: `${i18n.language === "ar" ? "0" : "unset"}`,
                  right: `${i18n.language === "en" ? "0" : "unset"}`,
                  top: "0",
                }}
              >
                <OverlayTrigger
                  trigger={["hover", "focus", "click"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover
                      id={`popover-positioned-top`}
                      className="border-0 rounded"
                    >
                      <Popover.Content className="text-center">
                        <img src={carPlate} alt="plate" width="200" />
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <Button variant="">
                    <img src={infoIcon} alt="info" />
                  </Button>
                </OverlayTrigger>
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.car_number ? props.errors.car_number : ""}
                </small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <Form.Control
                type="text"
                name="manufacturer"
                value={props.values.manufacturer}
                onChange={props.handleChange}
                className={`w-100 border-0 ${styles.input}`}
              />
              <Form.Label
                className="w-100"
                style={{
                  right: `${i18n.language === "ar" ? "-10%" : "unset"}`,
                  left: `${i18n.language === "en" ? "0" : "40%"}`,
                }}
              >
                {t("form.car.manufacturer")}
              </Form.Label>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.manufacturer ? props.errors.manufacturer : ""}
                </small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
              >
                <Form.Label
                  style={{
                    right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "unset"}`,
                  }}
                  className={`mb-0 mx-2 ${styles.label}`}
                >
                  {t("form.car.year")}
                </Form.Label>
                <Select
                  value={{
                    value: props.values.car_year.toString(),
                    label: props.values.car_year.toString(),
                  }}
                  options={yearsOptions}
                  className={`w-100 border-0 mt-4`}
                  name="car_year"
                  onChange={(values) =>
                    handleSelectChange(
                      values || { value: "2000", label: "2000" },
                      "car_year",
                      props
                    )
                  }
                />
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.car_year ? props.errors.car_year : ""}
                </small>
              </div>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="form-floating mb-3">
              <div
                className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
              >
                <Form.Label
                  style={{
                    right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "unset"}`,
                  }}
                  className={`mb-0 mx-2 ${styles.label}`}
                >
                  {t("form.car.type")}
                </Form.Label>
                <Select
                  value={{
                    value: props.values.car_type.toString(),
                    label: props.values.car_type.toString(),
                  }}
                  options={typeOptions}
                  className={`w-100 border-0 mt-4`}
                  name="car_type"
                  onChange={(values) =>
                    handleSelectChange(
                      values || { value: "0", label: "0" },
                      "car_type",
                      props
                    )
                  }
                />
              </div>
              <div className="px-2 pt-2 text-danger">
                <small>
                  {props.touched.car_type ? props.errors.car_type : ""}
                </small>
              </div>
            </Form.Group>
          </Form.Row>
          <div className="d-flex align-items-center justify-content-end">
            <Button
              className={`${styles.submit} bold-text border-0 d-flex align-items-center justify-content-around mx-3`}
              style={{ width: "35px", minWidth: "35px" }}
              type="button"
              variant="primary"
              onClick={() => handleBackClick(2)}
            >
              <img
                src={brownArrow}
                alt="prev"
                className={`${i18n.language === "en" ? "rotate" : ""}`}
              />
            </Button>
            <Button
              className={`${styles.submit} bold-text border-0 d-flex align-items-center justify-content-around`}
              type="submit"
              variant="primary"
            >
              <span>{t("form.submit")}</span>
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
