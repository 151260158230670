import { useTranslation } from "react-i18next";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Select, { OptionsType } from "react-select";
import ksaIcon from "../../assets/icons/ksa.svg";
import styles from "./style.module.scss";
import ReCAPTCHA from "react-google-recaptcha";

type formValuesType = {
  name: string;
  email: string;
  phone: string;
  company_name: string;
  store_name: string;
  website: string;
  city: string;
  store_type: string;
  number_of_branches: number;
  g_recaptcha_token: string;
};

type ContactInfoType = {
  handleFormSubmit: Function;
  data: formValuesType;
};

export const ContactInfo = ({ handleFormSubmit, data }: ContactInfoType) => {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({ ...data });
  const options = [
    { value: "riyadh", label: "Riyadh" },
    { value: "jeddah", label: "Jeddah" },
    { value: "dammam", label: "Dammam" },
  ];

  const storeTypeOptions = [
    { value: "restaurant", label: "Restaurant" },
    { value: "coffee", label: "Coffee" },
    { value: "pastries", label: "Pastries" },
  ];

  const branchesOptions = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
  ];

  function onChange(value: any, props: any) {
    setFormData({
      ...props.values,
      g_recaptcha_token: value,
    });
  }

  const handleBranchChange = (
    values: {
      value: string;
      label: string;
    },
    name: string,
    props: any
  ) => {
    setFormData({
      ...props.values,
      [name]: parseInt(values.value),
    });
  };

  const handleSelectChange = (value: any, name: string, props: any) => {
    setFormData({
      ...props.values,
      [name]: value.value,
    });
  };

  const getSelectedValues = (
    name: string,
    props: any,
    list: {
      value: string;
      label: string;
    }[]
  ) => {
    return props.values[name]?.value;
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(t("form.errors.required")),
    email: Yup.string()
      .required(t("form.errors.required"))
      .email(t("form.errors.email")),
    phone: Yup.string()
      .required(t("form.errors.required"))
      .min(9, t("form.errors.numbers.ten"))
      .max(9, t("form.errors.numbers.ten"))
      .matches(/^5[0-9]+$/, t("form.error.phone.format")),
    company_name: Yup.string().required(t("form.errors.required")),
    store_name: Yup.string().required(t("form.errors.required")),
    website: Yup.string(),
    city: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    store_type: Yup.string()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.oneMore")),
    number_of_branches: Yup.number()
      .required(t("form.errors.required"))
      .min(1, t("form.errors.select.one")),
    g_recaptcha_token: Yup.string().required(t("form.errors.required")),
  });

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values) => handleFormSubmit(values)}
      initialValues={formData}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              props.handleSubmit();
            }}
          >
            {/* your name */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  value={props.values.name}
                  onChange={props.handleChange}
                  onBlur={() => props.setFieldTouched("name")}
                  className={`w-100 border-0 ${styles.input}`}
                />
                <Form.Label
                  className="w-100"
                  style={{
                    right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "40%"}`,
                  }}
                >
                  {t("form.name")}
                </Form.Label>
                <div className="px-2 pt-2 text-danger">
                  <small>{props.touched.name ? props.errors.name : ""}</small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* your email */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  onBlur={() => props.setFieldTouched("email")}
                  className={`w-100 border-0 ${styles.input}`}
                />
                <Form.Label
                  className="w-100"
                  style={{
                    right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "40%"}`,
                  }}
                >
                  {t("form.email")}
                </Form.Label>
                <div className="px-2 pt-2 text-danger">
                  <small>{props.touched.email ? props.errors.email : ""}</small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* phone number */}
            <Form.Row>
              <Form.Group className="mb-3">
                <div
                  className={`w-100 border-0 ${styles.input} d-flex align-items-center justify-content-between px-3`}
                  dir="ltr"
                >
                  <div className="d-flex align-items-center">
                    <img src={ksaIcon} alt="ksa" />{" "}
                    <span className="mx-2">+966</span>
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={props.values.phone}
                      onChange={props.handleChange}
                      onBlur={() => props.setFieldTouched("phone")}
                      className={`w-100 border-0 ${styles.input}`}
                      placeholder="00 - 000 - 0000"
                    />
                  </div>
                </div>
                <div className="px-2 pt-2 text-danger">
                  <small>{props.touched.phone ? props.errors.phone : ""}</small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* company name */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  name="company_name"
                  value={props.values.company_name}
                  onChange={props.handleChange}
                  onBlur={() => props.setFieldTouched("company_name")}
                  className={`w-100 border-0 ${styles.input}`}
                />
                <Form.Label
                  style={{
                    right: `${i18n.language === "ar" ? "-10%" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "40%"}`,
                  }}
                >
                  {t("form.company.name")}
                </Form.Label>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.company_name
                      ? props.errors.company_name
                      : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* store name */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  name="store_name"
                  value={props.values.store_name}
                  onChange={props.handleChange}
                  onBlur={() => props.setFieldTouched("store_name")}
                  className={`w-100 border-0 ${styles.input}`}
                />
                <Form.Label
                  className="w-100"
                  style={{
                    right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "40%"}`,
                  }}
                >
                  {t("form.store.name")}
                </Form.Label>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.store_name ? props.errors.store_name : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <div
                  className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
                >
                  <Form.Label
                    style={{
                      right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                      left: `${i18n.language === "en" ? "0" : "unset"}`,
                    }}
                    className={`mb-0 mx-2 ${styles.label}`}
                  >
                    {t("form.store.type")}
                  </Form.Label>
                  <Select
                    placeholder=""
                    options={storeTypeOptions}
                    className="w-100 border-0 mt-4"
                    name="store_type"
                    value={getSelectedValues(
                      "store_type",
                      props,
                      storeTypeOptions
                    )}
                    onChange={(
                      value: OptionsType<{
                        value: string;
                        label: string;
                      }>
                    ) => handleSelectChange(value, "store_type", props)}
                    onBlur={() => props.setFieldTouched("store_type")}
                  />
                </div>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.store_type ? props.errors.store_type : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* number of branches */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <div
                  className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
                >
                  <Form.Label
                    style={{
                      right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                      left: `${i18n.language === "en" ? "0" : "unset"}`,
                    }}
                    className={`mb-0 mx-2 ${styles.label}`}
                  >
                    {t("form.branches")}
                  </Form.Label>
                  <Select
                    value={{
                      value: props.values.number_of_branches.toString(),
                      label: props.values.number_of_branches.toString(),
                    }}
                    options={branchesOptions}
                    className={`w-100 border-0 mt-4`}
                    name="number_of_branches"
                    onChange={(values) =>
                      handleBranchChange(
                        values || { value: "0", label: "0" },
                        "number_of_branches",
                        props
                      )
                    }
                    onBlur={() => props.setFieldTouched("number_of_branches")}
                  />
                </div>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.number_of_branches
                      ? props.errors.number_of_branches
                      : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* web or social media */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <Form.Control
                  type="text"
                  name="website"
                  value={props.values.website}
                  onChange={props.handleChange}
                  className={`w-100 border-0 ${styles.input}`}
                />
                <Form.Label
                  className="w-100"
                  style={{
                    right: `${i18n.language === "ar" ? "-13%" : "unset"}`,
                    left: `${i18n.language === "en" ? "0" : "40%"}`,
                  }}
                >
                  {t("form.socialLink")}
                </Form.Label>
                <div className="px-2 pt-2 text-danger">
                  <small>
                    {props.touched.website ? props.errors.website : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>
            {/* city */}
            <Form.Row>
              <Form.Group className="form-floating mb-3">
                <div
                  className={`d-flex align-items-center ${styles.input} ${styles.selectHeight}`}
                >
                  <Form.Label
                    style={{
                      right: `${i18n.language === "ar" ? "8px" : "unset"}`,
                      left: `${i18n.language === "en" ? "0" : "unset"}`,
                    }}
                    className={`mb-0 mx-2 ${styles.label}`}
                  >
                    {t("form.city")}
                  </Form.Label>
                  <Select
                    placeholder=""
                    options={options}
                    className={`w-100 border-0 mt-4`}
                    name="city"
                    value={getSelectedValues("city", props, options)}
                    onChange={(
                      value: OptionsType<{
                        value: string;
                        label: string;
                      }>
                    ) => handleSelectChange(value, "city", props)}
                    onBlur={() => props.setFieldTouched("city")}
                  />
                </div>
                <div className="px-2 pt-2 text-danger">
                  <small>{props.touched.city ? props.errors.city : ""}</small>
                </div>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group>
                <ReCAPTCHA
                  className="mb-3"
                  sitekey={"6LeY0osiAAAAANIzKauhJgaZwFBcmaviftCjosIG"}
                  onChange={(value) => onChange(value, props)}
                />
                <div className="px-2  text-danger">
                  <small>
                    {props.errors.g_recaptcha_token &&
                    props.touched.g_recaptcha_token
                      ? props.errors.g_recaptcha_token
                      : ""}
                  </small>
                </div>
              </Form.Group>
            </Form.Row>

            <div className="d-flex align-items-center justify-content-end">
              <Button
                className={`${styles.submit} bold-text border-0 d-flex align-items-center justify-content-around`}
                type="submit"
                variant="primary"
              >
                <span>{t("form.submit")}</span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
