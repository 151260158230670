import { useState } from "react";
import { Alert } from "../../components/alert";
import { ContactInfo } from "./contactInfo";
import axios from "axios";

export const PartnerRegistration = () => {
  const [show, setShow] = useState(false);

  const [intialData, setIntialData] = useState({
    name: "",
    email: "",
    phone: "",
    company_name_ar: "",
    company_name: "",
    commercial_registration: "",
    store_name_ar: "",
    store_name: "",
    website: "",
    city: "",
    store_type: "",
    number_of_branches: 0,
    g_recaptcha_token: "",
  });

  const handleSubmit = (data: any, event?: { preventDefault: () => void }) => {
    event?.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_CORE_URL}website/v1/partners/register`,
        { ...data }
      )
      .then(() => {
        setShow(true);
      })
      .catch((error) => console.log(error));
  };

  const handleFormSubmit = (values: any) => {
    setIntialData(values);
    handleSubmit({
      ...values,
    });
  };

  return (
    <>
      <ContactInfo handleFormSubmit={handleFormSubmit} data={intialData} />
      <Alert show={show} setShow={setShow} />
    </>
  );
};
